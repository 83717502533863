import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import Pdf from '../components/Pdf';

const PdfRender = () => (
  <div className="pdf-container">
    <PDFViewer style={{ width: '100%', height: '1500px', border:'0' }}>
      <Pdf />
    </PDFViewer>
  </div>
);

export default PdfRender;