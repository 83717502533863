import React, { useState, useEffect, useRef } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Overlay from 'ol/Overlay';
import LineString from 'ol/geom/LineString';
import 'ol/ol.css';
import './MapComponent.css';
import { Style, Fill, Stroke, Circle } from 'ol/style';
import FullScreen from 'ol/control/FullScreen';

const MapComponent = props => {
  const { line_name, direction, sort, date } = useParams();
  const [lineRename, setLineRename] = useState(line_name.toString() === '81' ? 'A1' : line_name.toString() === '86' ? 'C1' : line_name.toString() === '87' ? 'C2' : line_name.toString() === '88' ? 'C3' : line_name.toString() === '22i' ? '22' : line_name.toString() === '22yi' ? '22y' : line_name.toString() === '24i' ? '24' : line_name.toString() === '24yi' ? '24y' : line_name);
  const [jsonDataLine, setJsonDataLine] = useState(null);
  const [selectedFeatureId, setSelectedFeatureId] = useState(null);
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const overlayRef = useRef(null);
  const [lastLineName, setLastLineName] = useState(null);
  var fullscreenFalse = document.createElement('i');
  fullscreenFalse.setAttribute('class', 'full-screen');

  const [mapCenter, setMapCenter] = useState(null);
  const [mapZoom, setMapZoom] = useState(null);
  const [mapViewState, setMapViewState] = useState({center: null, zoom: null});

  useEffect(() => {
    const newLineRename = line_name.toString() === '81' ? 'A1' : line_name.toString() === '86' ? 'C1' : line_name.toString() === '87' ? 'C2' : line_name.toString() === '88' ? 'C3' : line_name.toString() === '22i' ? '22' : line_name.toString() === '22yi' ? '22y' : line_name.toString() === '24i' ? '24' : line_name.toString() === '24yi' ? '24y' : line_name;
    if (newLineRename !== lineRename) {
      setLastLineName(lineRename); // Előző érték mentése, mielőtt frissítenénk a lineRename-t
      setLineRename(newLineRename);
    }
  }, [line_name, lineRename]);

  useEffect(() => {
      // Ha van megnyitott popup, zárjuk be az adatok frissítése előtt
  if (overlayRef.current) {
    overlayRef.current.setPosition(undefined);
  }
    const apiKey = 'Njy59SaRSe53LsDUQY9hIhrfxSp4V1kjvVRzD9EHG0pIHTtT1F';
    const baseUrl = `https://www.menetrend-debrecen.hu/menetrend_backend`;
    fetch(`${baseUrl}/map-line/${lineRename}/${direction}`)
      .then(response => response.json())
      .then(data => {
        setJsonDataLine(data);

        const minLat = Math.min(...data.data.map.map(point => point.f_koordx));
        const maxLat = Math.max(...data.data.map.map(point => point.f_koordx));
        const minLon = Math.min(...data.data.map.map(point => point.f_koordy));
        const maxLon = Math.max(...data.data.map.map(point => point.f_koordy));

        const calculateZoom = (minLat, maxLat, minLon, maxLon) => {
          const latDiff = maxLat - minLat;
          const lonDiff = maxLon - minLon;
          const maxDiff = Math.max(latDiff, lonDiff);
          return 8.5 - Math.log2(maxDiff);
        };

        const newZoom = calculateZoom(minLat, maxLat, minLon, maxLon);
        const centerLat = (minLat + maxLat) / 2;
        const centerLon = (minLon + maxLon) / 2;
        const newCenter = fromLonLat([centerLat, centerLon]);

        if (mapRef.current && (lastLineName !== lineRename)) {
          //console.log('frissul');
          mapRef.current.getView().setCenter(newCenter);
          mapRef.current.getView().setZoom(newZoom);
          // Itt frissítheted a lastLineName-t, ha szükséges
          setLastLineName(lineRename);
        }
        setMapViewState({center: newCenter, zoom: newZoom});
        //console.log(newCenter);
        //console.log(newZoom);
      })
      
      .catch(error => {
        console.error('Hiba a JSON line adatok lekérése során', error);
      });
  }, [lineRename, direction, lastLineName]);

  useEffect(() => {

    if (props.loadedStops && jsonDataLine.data) {

      // data
      const pointCoordinates = props.loadedStops.travelTime.map(point => fromLonLat([point.f_koordx, point.f_koordy], 'EPSG:3857', 'EPSG:4326'));
      const f_nev = props.loadedStops.travelTime.map(data => data.f_nev);
      const connected_lines = props.loadedStops.travelTime.map(data => data.connected_lines);
      const connected_type = props.loadedStops.travelTime.map(data => data.connected_type);
      const connected_sorrend = props.loadedStops.travelTime.map(data => data.connected_sorrend);
      const lineCoordinates = jsonDataLine.data.map.map(point => fromLonLat([point.f_koordx, point.f_koordy], 'EPSG:3857', 'EPSG:4326'));
      
      // zoom
      const calculateZoom = (minLat, maxLat, minLon, maxLon) => {
        const latDiff = maxLat - minLat;
        const lonDiff = maxLon - minLon;
        const maxDiff = Math.max(latDiff, lonDiff);
        return 8.5 - Math.log2(maxDiff);
      };
      
      const minLat = Math.min(...jsonDataLine.data.map.map(point => point.f_koordx));
      const maxLat = Math.max(...jsonDataLine.data.map.map(point => point.f_koordx));
      const minLon = Math.min(...jsonDataLine.data.map.map(point => point.f_koordy));
      const maxLon = Math.max(...jsonDataLine.data.map.map(point => point.f_koordy));
      const zoom = calculateZoom(minLat, maxLat, minLon, maxLon);

      // center
      const centerLat = (minLat + maxLat) / 2;
      const centerLon = (minLon + maxLon) / 2;
      const center = fromLonLat([centerLat, centerLon], 'EPSG:3857', 'EPSG:4326');

      // line
      const lineString = new LineString(lineCoordinates, 'XY', false);
      const lineFeature = new Feature({
        geometry: lineString,
      });
      const stroke = new Stroke({
        color: '#1a499f',
        width: 2,
      });

      if (lastLineName !== null && lineRename !== lastLineName) {
        setMapCenter(center); // Frissítsd az új középpontot
        setMapZoom(zoom); // Frissítsd az új zoom szintet
        setLastLineName(lineRename); // Frissítsd az utolsó járat nevét
      }

      if (!mapRef.current) { // Térkép inicializálása az új adatokkal
        mapRef.current = new Map({
          target: 'map',
          layers: [
            new TileLayer({
              source: new OSM(),
            }),
            new VectorLayer({
              source: new VectorSource({
                features: [lineFeature],
              }),
              style: new Style({
                stroke: stroke,
              }),
            }),
            new VectorLayer({
              source: new VectorSource({
                features: pointCoordinates.map((coord, index) => {
                  const feature = new Feature({
                    geometry: new Point(coord),
                  });
                  setSelectedFeatureId(index);
                  feature.setStyle(function (feature) {
                    return new Style({
                      image: new Circle({
                        radius: 6,
                        fill: new Fill({ color: sort-1 === index ? 'rgb(255, 233, 48)' : '#1a499f' }),
                        stroke: new Stroke({ color: 'white', width: 2 })
                      }),
                    });
                  });
                  return feature;
                }),
              }),
            }),
          ],
          view: new View({
            center: mapViewState.center || fromLonLat([21.625580092274436, 47.53194767220976]), // Alapértelmezett érték, ha nincs még meghatározva
            zoom: mapViewState.zoom || 7, 
          }),
        });
        
        //popup
        overlayRef.current = new Overlay({
          element: document.getElementById('popup'),
          autoPan: true,
          autoPanAnimation: {
            duration: 250,
          },
        });
        mapRef.current.addOverlay(overlayRef.current);

        //fullscreen button
        mapRef.current.addControl(new FullScreen({ label: fullscreenFalse, tipLabel: 'Teljes képernyős nézet' }));

      } 
      
      else { // Térkép már inicializálva van, frissítse a régi pontokat
        
        const vectorLayer = mapRef.current.getLayers().getArray()[1];
        const source = vectorLayer.getSource();
        source.clear();
        source.addFeature(lineFeature);

        const markerLayer = mapRef.current.getLayers().getArray()[2];
        const markerSource = markerLayer.getSource();
        markerSource.clear();
        pointCoordinates.forEach((coord, index) => {
          const feature = new Feature({
            geometry: new Point(coord),
            index:index
          });

          setSelectedFeatureId(index);

          feature.setStyle(function (feature) {
            return new Style({
              image: new Circle({
                radius: 6,
                fill: new Fill({ color: sort-1 === index ? 'rgb(255, 233, 48)' : '#1a499f' }),
                stroke: new Stroke({ color: 'white', width: 2 })
              }),
            });
          });

          markerSource.addFeature(feature);
        });

      }

      mapRef.current.on('click', function (event) {
        const feature = mapRef.current.forEachFeatureAtPixel(event.pixel, function (feature) {
          // Itt adja vissza a feature-t, ha az egy pont
          if (feature.getGeometry().getType() === 'Point') {
            return feature;
          }
        });

        if (feature) {
          const pointCoordinates = feature.getGeometry().getCoordinates();
          overlayRef.current.setPosition(pointCoordinates);
          const content = `
          <div class="buble-title">${f_nev[feature.values_.index]}</div>
          <div class="buble-content">Átszállási lehetőségek:
            <div>
            ${connected_type[feature.values_.index] && connected_lines[feature.values_.index] ? 
              connected_type[feature.values_.index].map((type, index) => {
                if (type === 'VI' && connected_lines[feature.values_.index][index]) {
                  return `<div class="point2-vi">${connected_lines[feature.values_.index][index]}</div>`;
                }
                return null;
              }).join('') 
              : 'Nincs elérhető adat'}
              ${connected_type[feature.values_.index] && connected_lines[feature.values_.index] ? 
                connected_type[feature.values_.index].map((type, index) => {
                  if (type === 'TR' && connected_lines[feature.values_.index][index]) {
                    return `<div class="point2-tr">${connected_lines[feature.values_.index][index]}</div>`;
                  }
                  return null;
                }).join('') 
                : 'Nincs elérhető adat'}
              ${connected_type[feature.values_.index] && connected_lines[feature.values_.index] ? 
                connected_type[feature.values_.index].map((type, index) => {
                  if (type === 'AU' && connected_lines[feature.values_.index][index]) {
                    return `<a href='/live/${connected_lines[feature.values_.index][index]}/${direction}/${connected_sorrend[feature.values_.index][index]}/1/${date}'><div class="point2-au">${connected_lines[feature.values_.index][index]}</div></a>`;
                  }
                  return null;
                }).join('') 
                : 'Nincs elérhető adat'}
            </div>
          </div>
        `;
          document.getElementById('popup-content').innerHTML = content;
          // Állapot frissítése a kattintott pont azonosítójával
          setSelectedFeatureId(feature.values_.index+1);
          navigate(`../live/${line_name}/${direction}/${feature.values_.index+1}/1/${date}`);

        } else {
          overlayRef.current.setPosition(undefined);
          setSelectedFeatureId(null); // Nincs kiválasztott pont
        }

      });
      
    }
  }, [props.loadedStops, jsonDataLine, fullscreenFalse, selectedFeatureId, date, direction, line_name, navigate, sort, lastLineName, lineRename, mapCenter, mapZoom, mapViewState]);

  return (
    <div className="map-container">
      <div id="map" className="map"></div>
      <div id="popup" className="ol-popup">
        <div id="popup-content"></div>
        <button id="popup-closer" className="ol-popup-closer" onClick={() => overlayRef.current.setPosition(undefined)}></button>
      </div>
    </div>
  );
};

export default MapComponent;