import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Spinner.css';

const Spinner = ({ onHourChange, hour, items }) => {
    const { date } = useParams();
    const [spinnerHour, setSpinnerHour] = useState(hour);
    const [nextTime, setNextTime] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    const handleIncrement = () => {
        if (spinnerHour >= 23) return;
        const newValue = spinnerHour + 1;
        setSpinnerHour(newValue);
        onHourChange(newValue);
    };

    const handleDecrement = () => {
        if (spinnerHour <= 0) return;
        const newValue = spinnerHour - 1;
        setSpinnerHour(newValue);
        onHourChange(newValue);
    };

    const calculateNextTime = () => {
        let calculationDate = new Date();
        if (date) {
            const [year, month, day] = date.split('-').map(num => parseInt(num, 10));
            calculationDate = new Date(year, month - 1, day);
        }
        calculationDate.setHours(spinnerHour);
    
        const now = new Date();
        if (items.data.rows_returned !== 0) {
            for (let i = 0; i < items.data.liveStopTimes.length; i++) {
                let timeData = items.data.liveStopTimes[i];
                const targetTime = new Date(calculationDate.getTime());
                targetTime.setMinutes(timeData.ind, 0, 0); // Seconds set to 0
    
                if (targetTime > now) {
                    const diff = targetTime - now;
                    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
                    let minutes = Math.floor((diff / (1000 * 60)) % 60);
                    const seconds = Math.floor((diff / 1000) % 60);
    
                    // Ha még vannak másodpercek, kerekítsük a perceket felfelé
                    if (seconds > 0) {
                        minutes += 1;
                    }
    
                    setNextTime({ days, hours, minutes: minutes % 60, seconds: 0 }); // A percet modulo 60-ra állítjuk, ha túlkerekítünk egy órára
                    return;
                }
            }
        }
        // Ha nincs több járat, vagy már nem találunk a jövőbenit
        setNextTime({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    };

    useEffect(() => {
        calculateNextTime(); // Az oldal betöltésekor egyszer lefut
        const interval = setInterval(calculateNextTime, 1000); // Minden másodpercben frissít

        return () => clearInterval(interval); // Komponens eltűnésekor törli az intervallumot
    }, [date, hour, items.data.liveStopTimes, items.data.rows_returned, spinnerHour]);

    const displayNextTime = () => {
        const { days, hours, minutes } = nextTime;
        if (days > 0 || hours > 0 || minutes > 0) {
            return (
                <>
                    {days > 0 && <div className="daytext">{days} nap</div>}
                    <div>{String(hours).padStart(2, '0') + ":" + String(minutes).padStart(2, '0')}</div>
                </>
            );
        }
        return '-';
    };

    return (
        <div className="spinner">
            <div className='spinner-container'>
                <div className='input-number'>
                    <button type='button' onClick={handleDecrement}>&minus;</button>
                    <div className='hour-big-container'>
                        <div className='hour-big'>{String(spinnerHour).padStart(2, '0')}</div>
                        <div className='hour-text'>óra</div>
                    </div>
                    <button type='button' onClick={handleIncrement}>&#43;</button>
                </div>
            </div>

            <div className='next-time-box'>
                <div className='next-time-text'><span style={{lineHeight:'20px',textAlign:'center'}}>A következő járat menetrend szerint</span></div>
                <div className='next-time'>{displayNextTime()}</div>
                <div className='next-time-text'>múlva érkezik</div>
            </div>
        </div>
    );
};

export default Spinner;
