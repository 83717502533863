import React, { } from 'react';
import './StopTimeList.css';

const StopTimesList = props => {

    if (props.length === 0) {
      return (
        <div>Nincs adat.</div>
      );
    }

   const sharedNyomvid = props && props.sharedNyomvid;
   const sharedClass = props && props.sharedClass;

    return (
      <>
      <div className='tables'>

      <div className='table-box'>
        <table cellSpacing='0' cellPadding='0' className='times'>
          <thead>
            <tr>
              <th colSpan='2'>MUNKANAP, TANITÁSI IDŐSZAK</th>
            </tr>
            <tr>
                <th className='hour-cell'>ÓRA</th>
                <th>PERCEK</th>
            </tr>
          </thead>
          <tbody>
            {props.items.map((data, index1) => (
              <tr key={'col1_1' + index1}>
                <td key={'col1_2' + index1} className='hour'>{data.hour}</td>
                <td key={'col1_3' + index1} className='min'>
                  {data.min && data.f_nyomvid && Array.isArray(sharedNyomvid) &&  
                    data.min.map((mindata, index2) => {
                      if (data.naptipus[index2].includes('1')) {
                        // Megkeresi a megfelelő indexet a sharedNyomvid tömbben, ha az létezik és tömb
                        const nyomvidIndex = sharedNyomvid.findIndex(nyomvid => nyomvid === data.f_nyomvid[index2]);

                        // Kiválasztja a megfelelő osztályt, vagy 'color-none', ha nincs találat
                        const className = nyomvidIndex !== -1 ? sharedClass[nyomvidIndex] : 'color-none';

                        return (
                          <span key={'col_1_1' + index2} className={className}>
                            {data.sign[index2] && data.sign[index2]}{mindata}
                          </span>
                        );
                      }
                      return null;
                    })}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>

      <div className='table-box'>
        <table cellSpacing='0' cellPadding='0' className='times'>
          <thead>
            <tr>
              <th colSpan='2'>MUNKANAP, TANITÁSI SZÜNET</th>
            </tr>
            <tr>
                <th className='hour-cell'>ÓRA</th>
                <th>PERCEK</th>
            </tr>
          </thead>
          <tbody>
            {props.items.map((data, index1) => (
              <tr key={'col2_1' + index1}>
                <td key={'col2_2' + index1} className='hour'>{data.hour}</td>
                <td key={'col2_3' + index1} className='min'>
                  {data.min && data.f_nyomvid && Array.isArray(sharedNyomvid) &&  
                    data.min.map((mindata, index2) => {
                      if (data.naptipus[index2].includes('2')) {
                        // Megkeresi a megfelelő indexet a sharedNyomvid tömbben, ha az létezik és tömb
                        const nyomvidIndex = sharedNyomvid.findIndex(nyomvid => nyomvid === data.f_nyomvid[index2]);

                        // Kiválasztja a megfelelő osztályt, vagy 'color-none', ha nincs találat
                        const className = nyomvidIndex !== -1 ? sharedClass[nyomvidIndex] : 'color-none';

                        return (
                          <span key={'col_2_1' + index2} className={className}>
                            {data.sign[index2] && data.sign[index2]}{mindata}
                          </span>
                        );
                      }
                      return null;
                    })}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot></tfoot>
        </table>
        </div>

        <div className='table-box'>
        <table cellSpacing='0' cellPadding='0' className='times'>
          <thead>
            <tr>
              <th colSpan='2'>SZABADNAP</th>
            </tr>
            <tr>
                <th className='hour-cell'>ÓRA</th>
                <th>PERCEK</th>
            </tr>
          </thead>
          <tbody>
            {props.items.map((data, index1) => (
              <tr key={'col3_1' + index1}>
                <td key={'col3_2' + index1} className='hour'>{data.hour}</td>
                <td key={'col3_3' + index1} className='min'>
                  {data.min && data.f_nyomvid && Array.isArray(sharedNyomvid) &&  
                    data.min.map((mindata, index2) => {
                      if (data.naptipus[index2].includes('3')) {
                        // Megkeresi a megfelelő indexet a sharedNyomvid tömbben, ha az létezik és tömb
                        const nyomvidIndex = sharedNyomvid.findIndex(nyomvid => nyomvid === data.f_nyomvid[index2]);

                        // Kiválasztja a megfelelő osztályt, vagy 'color-none', ha nincs találat
                        const className = nyomvidIndex !== -1 ? sharedClass[nyomvidIndex] : 'color-none';

                        return (
                          <span key={'col_3_1' + index2} className={className}>
                            {data.sign[index2] && data.sign[index2]}{mindata}
                          </span>
                        );
                      }
                      return null;
                    })}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot></tfoot>
        </table>
        </div>

        <div className='table-box'>
        <table cellSpacing='0' cellPadding='0' className='times'>
          <thead>
            <tr>
              <th colSpan='2'>MUNKASZÜNETI NAP</th>
            </tr>
            <tr>
                <th className='hour-cell'>ÓRA</th>
                <th>PERCEK</th>
            </tr>
          </thead>
          <tbody>
            {props.items.map((data, index1) => (
              <tr key={'col4_1' + index1}>
                <td key={'col4_2' + index1} className='hour'>{data.hour}</td>
                <td key={'col4_3' + index1} className='min'>
                  {data.min && data.f_nyomvid && Array.isArray(sharedNyomvid) &&  
                    data.min.map((mindata, index2) => {
                      if (data.naptipus[index2].includes('4')) {
                        // Megkeresi a megfelelő indexet a sharedNyomvid tömbben, ha az létezik és tömb
                        const nyomvidIndex = sharedNyomvid.findIndex(nyomvid => nyomvid === data.f_nyomvid[index2]);

                        // Kiválasztja a megfelelő osztályt, vagy 'color-none', ha nincs találat
                        const className = nyomvidIndex !== -1 ? sharedClass[nyomvidIndex] : 'color-none';

                        return (
                          <span key={'col_4_1' + index2} className={className}>
                            {data.sign[index2] && data.sign[index2]}{mindata}
                          </span>
                        );
                      }
                      return null;
                    })}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot></tfoot>
        </table>
        
        </div>
        
      </div>
      </>
    );

};

export default StopTimesList;