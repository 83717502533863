import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import Logo from '../images/hirdetmeny_logo.png';
import BusIcon from '../images/bus-pdf.png';
import Arrow from '../images/arrow-pdf.png';
import SingleArrow from '../images/single-arrow.png';
import GreenLine from '../images/green-line.png';
import DkvLogo from '../images/dkv-logo-white.png';

Font.registerHyphenationCallback(word => {
  // Return entire word as unique part
  return [word];
});

Font.register({
  family: "Montserrat",
  src:
    "../dkv-basesite/fonts/Montserrat-Regular.ttf"
});

Font.register({
  family: "Montserrat-Bold",
  src:
    "../dkv-basesite/fonts/Montserrat-Bold.ttf"
});
// Create styles
const styles = StyleSheet.create({
  document: {
    height:'100%'
  },
  page: {
    flexDirection: 'col',
    fontFamily: "Montserrat",
    height:'100%'
  },
  section1: {
    display:'flex',
    flexDirection:'row',
    width:'100%',
    height:'12%'
  },
  section2: {
    display:'flex',
    flexDirection:'row',
    width:'100%',
    height:'83%'
  },
  section3: {
    display:'flex',
    flexDirection:'row',
    width:'50%',
    height:'100%'
  },
  section4: {
    display:'flex',
    width:'50%',
    height:'100%'
  },
  section5: {
    display:'flex',
    flexDirection:'column',
    width:'35%',
    height:'100%'
  },
  section6: {
    display:'flex',
    width:'65%',
    height:'100%'
  },
  section7: {
    display:'flex',
    width:'100%',
    fontSize:'12px',
    textAlign:'center',
    fontFamily: 'Montserrat-Bold',
    margin:'5px 0'
  },
  section8: {
    display:'flex',
    width:'100%',
    height:'5%',
    flexDirection:'row'
  },
  section9: {
    display:'flex',
    width:'35%',
    height:'100%'
  },
  section10: {
    display:'flex',
    width:'65%',
    height:'60px'
  },
  section11: {
    display:'flex',
    width:'85%',
    height:'100%',
    flexDirection:'column',
    margin:'17px 5% 0 10%'
  },
  section12: {
    display:'flex',
    width:'20%',
    height:'100%'
  },
  section13: {
    display:'flex',
    width:'40%',
    height:'100%'
  },
  section14: {
    display:'flex',
    width:'40%',
    paddingTop:'9px'
  },
  section15: {
    display:'flex',
    width:'100%',
    marginTop:'9px',
    height:'19px',
    backgroundColor:'#7cb54e'
  },
  bus_icon:{
    padding:'6px'
  },
  section16: {
    position:'relative',
    display:'flex',
    width:'100%',
    fontSize:'50px',
    color:'#7cb54e',
    fontFamily: 'Montserrat-Bold'
  },
  section17: {
    display:'flex',
    width:'50%',
    fontSize:'12px',
    textAlign:'left',
    fontFamily: 'Montserrat-Bold'
  },
  section18: {
    display:'flex',
    width:'50%',
    fontSize:'12px',
    textAlign:'left',
    fontFamily: 'Montserrat-Bold'
  },
  section19: {
    display:'flex',
    flexDirection:'row',
    width:'100%',
    fontSize:'10px',
    textAlign:'left',
    fontFamily: 'Montserrat-Bold',
    margin:'5px 0 20px 0'
  },
  section20: {
    display:'flex',
    width:'100%',
    flexDirection:'row',
    marginBottom:'20px'
  },
  section21: {
    display:'flex',
    width:'47%',
    fontFamily: 'Montserrat-Bold',
    fontSize:'8px',
  },
  section22: {
    display:'flex',
    fontFamily: 'Montserrat',
    fontSize:'8px',
    margin:'5px 0 3px 0'
  },
  section23: {
    display:'flex',
    margin:'3px 0 0 0',
    padding:'5px 0 0 0',
    border:'none'
  },
  section24: {
    display:'flex',
    padding:'0 5px',
    fontSize:'10px',
    border:'none',
    width:'15%',
    height:'22px',
    alignItems:'center'
  },
  section25: {
    display:'flex',
    padding:'0 3px',
    fontSize:'10px',
    width:'100%',
    fontFamily: 'Montserrat',
    flexDirection:'row',
  },
  section26: {
    display:'flex',
    flexDirection:'row',
    backgroundColor:'#f3f3f3'
  },
  section27: {
    margin:'0 2px',
    fontSize:'10px',
  },
  section28: {
    display:'flex',
    flexDirection:'row',
    backgroundColor:'#dfdfdf'
  },
  section29: {
    display:'flex',
    backgroundColor:'#929292',
    height:'5px'
  },
  section30: {
    display:'flex',
    backgroundColor:'#ffd075',
    height:'5px'
  },
  section31: {
    display:'flex',
    flexDirection:'row',
    backgroundColor:'#fff9ec'
  },
  section32: {
    display:'flex',
    flexDirection:'row',
    backgroundColor:'#feefd3'
  },
  section33: {
    display:'flex',
    flexDirection:'row',
    backgroundColor:'#f4ffeb'
  },
  section34: {
    display:'flex',
    flexDirection:'row',
    backgroundColor:'#dafdbe'
  },
  section35: {
    display:'flex',
    flexDirection:'row',
    backgroundColor:'#e8f3fe'
  },
  section36: {
    display:'flex',
    flexDirection:'row',
    backgroundColor:'#c4e0fd'
  },
  section37: {
    display:'flex',
    backgroundColor:'#275ac0',
    height:'5px'
  },
  section38: {
    display:'flex',
    backgroundColor:'#7cb54e',
    height:'5px'
  },
  section39: {
    display:'flex',
    flexDirection:'row',
    width:'100%'
  },
  section40: {
    display:'flex'
  },
  section41: {
    display:'flex',
    width:'100%',
    flexDirection:'row'
  },
  section42: {
    display:'flex',
    width:'10%',
    fontSize:'10px',
    textAlign:'center',
    fontFamily: 'Montserrat-Bold',
    marginTop:'1px'
  },
  section43: {
    display:'flex',
    width:'10%',
    margin:'0 3% 0 1%'
  },
  section44: {
    display:'flex',
    position:'relative',
    right:'15px',
    top:'1px',
    fontFamily: 'Montserrat-Bold',
    fontSize:'12px',
    borderLeft: '2px solid #ccc',
    padding:'0 0 5px 15px',
    zIndex:'1',
    minHeight:'25px'
  },
  section47: {
    display:'flex',
    position:'relative',
    right:'15px',
    fontFamily: 'Montserrat-Bold',
    fontSize:'12px',
    borderLeft: '2px solid #ccc',
    padding:'0 0 0 15px',
    zIndex:'1'
  },
  section45: {
    display:'flex',
    backgroundColor:'#7cb54e',
    width:'15px',
    height:'15px',
    borderRadius:'50%',
    alignSelf:'center',
    zIndex:'100'
  },
  section46: {
    fontSize:'10px',
    display:'flex',
    position:'relative',
    right:'15px',
    width:'76%',
    top:'1px',
    fontFamily: 'Montserrat-Bold',
    borderLeft: '2px solid #ccc',
    padding:'0 0 5px 15px',
    zIndex:'1',
    minHeight:'25px'
  },
  section48: {
    display:'flex',
    flexDirection:'row',
    position:'relative',
    right:'15px',
    top:'1px',
    fontSize:'8px',
    width:'76%',
    padding:'0 0 5px 15px',
    zIndex:'1',
    color: '#7cb54e',
    width:'100%'
  },
  section49: {
    display:'flex',
    backgroundColor:'#7cb54e',
    width:'14px',
    height:'12px',
    color:'#fff',
    fontFamily: 'Montserrat-Bold',
    textAlign:'center',
    borderRadius:'50%',
    padding:'1px',
    marginRight:'5px'
  },
  arrow: {
    width:'16px',
    height:'17px'
  },
  single_arrow: {
    width:'15px',
    height:'7px',
    margin:'5px 0'
  },
  dkv_logo_white: {
    display:'flex',
    position:'relative',
    width:'70px',
    bottom:'26px',
    marginLeft:'30px'
  },
  section50: {
    display:'flex',
    flexDirection:'row',
    position:'relative',
    right:'39px',
    top:'1px',
    fontSize:'8px',
    width:'76%',
    padding:'5px 0 5px 15px',
    zIndex:'1',
    color: '#7cb54e',
    width:'100%'
  },
  section51: {
    display:'flex',
    fontSize:'16px',
    padding:'5px 0 20px 15px',
    fontFamily: 'Montserrat-Bold',
    color: '#7cb54e',
    width:'100%',
    lineHeight:'1.3'
  },
  section52: {
    display:'flex',
    fontSize:'14px',
    color: '#7cb54e',
    width:'100%',
    fontFamily: 'Montserrat'
  },
  text: {
    fontFamily: 'Montserrat',
    width:'100%'
  }
});

// Create Document Component
const Pdf = () => (

  <Document style={styles.document}>
    <Page size="A4" style={styles.page}>

      <View style={styles.section1}>
        <View style={styles.section3}>

          <View style={styles.section12}>
            <View style={styles.section15}></View>
            <View style={styles.section40}>
              <Image src={BusIcon} style={styles.bus_icon} />
            </View>
          </View>
          
          <View style={styles.section13}>
            <View style={styles.section15}></View>
            <View style={styles.section16}><Text>2</Text></View>
          </View>
          
          <View style={styles.section14}>
            <Image src={Logo} style={styles.logo}/>
          </View>
          
        </View>

        <View style={styles.section4}>
          <View style={styles.section51}>
            <Text>Nagyállomás</Text>
            <Image src={SingleArrow} style={styles.single_arrow} />
            <Text>Körjárat</Text>
            <View style={styles.section52}>
              <Text>Doberdő utca - Nagyállomás</Text>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.section2}>
        <View style={styles.section5}>
          <View style={styles.section7}>
            <Text>Megállók és menetidő</Text>
          </View>

          <View style={styles.section11}>

            <View style={styles.section41}>
              <View style={styles.section42}></View>
              <View style={styles.section43}>
                <View style={styles.section45}></View>
              </View>
              <View style={styles.section44}>
                <Text>Nagyállomás</Text>
                <View style={styles.section50}>
                  <Image src={Arrow} style={styles.arrow} />
                </View>
              </View>


            </View>

            <View style={styles.section41}>
              <View style={styles.section42}></View>
              <View style={styles.section43}>
                <View style={styles.section45}></View>
              </View>
              <View style={styles.section46}>
                <Text>Városháza</Text>
                <View style={styles.section48}>
                  <Text style={styles.section49}>b</Text>
                  <Text>91, 14, 25, 25Y, 12, 7, 91, 14, 25, 7, 91, 14, 25</Text>
                </View>
              </View>
            </View>

            <View style={styles.section41}>
              <View style={styles.section42}><Text>1'</Text></View>
              <View style={styles.section43}>
                <View style={styles.section45}></View>
              </View>
              <View style={styles.section46}>
                <Text>Kossuth tér</Text>
                <View style={styles.section48}>
                  <Text style={styles.section49}>b</Text>
                  <Text>91, 14, 25, 25Y, 12, 7, 91, 14, 25, 7, 91, 14, 25</Text>
                </View>
              </View>
            </View>

            <View style={styles.section41}>
              <View style={styles.section42}><Text>3'</Text></View>
              <View style={styles.section43}>
                <View style={styles.section45}></View>
              </View>
              <View style={styles.section46}>
                <Text>Kálvin tér</Text>
                <View style={styles.section48}>
                  <Text style={styles.section49}>b</Text>
                  <Text>91, 14, 25, 25Y, 12, 7, 91, 14, 25, 7, 91, 14, 25</Text>
                </View>
              </View>
            </View>

            <View style={styles.section41}>
              <View style={styles.section42}><Text>5'</Text></View>
              <View style={styles.section43}>
                <View style={styles.section45}></View>
              </View>
              <View style={styles.section46}>
                <Text>Kölcsey központ (Hunyadi János utca)</Text>
                <View style={styles.section48}>
                  <Text style={styles.section49}>b</Text>
                  <Text>91, 14, 25, 25Y, 12, 7, 91, 14, 25, 7, 91, 14, 25</Text>
                </View>
              </View>
            </View>

            <View style={styles.section41}>
              <View style={styles.section42}><Text>7'</Text></View>
              <View style={styles.section43}>
                <View style={styles.section45}></View>
              </View>
              <View style={styles.section46}>
                <Text>Csemete utca</Text>
                <View style={styles.section48}>
                  <Text style={styles.section49}>b</Text>
                  <Text>91, 14, 25, 25Y, 12, 7, 91, 14, 25, 7, 91, 14, 25</Text>
                </View>
              </View>
            </View>

            <View style={styles.section41}>
              <View style={styles.section42}><Text>9'</Text></View>
              <View style={styles.section43}>
                <View style={styles.section45}></View>
              </View>
              <View style={styles.section46}>
                <Text>Dózsa György utca</Text>
                <View style={styles.section48}>
                  <Text style={styles.section49}>b</Text>
                  <Text>91, 14, 25, 25Y, 12, 7, 91, 14, 25, 7, 91, 14, 25</Text>
                </View>
              </View>
            </View>

            <View style={styles.section41}>
              <View style={styles.section42}><Text>10'</Text></View>
              <View style={styles.section43}>
                <View style={styles.section45}></View>
              </View>
              <View style={styles.section46}>
                <Text>Nádor utca</Text>
                <View style={styles.section48}>
                  <Text style={styles.section49}>b</Text>
                  <Text>91, 14, 25, 25Y, 12, 7, 91, 14, 25, 7, 91, 14, 25</Text>
                </View>
              </View>
            </View>

            <View style={styles.section41}>
              <View style={styles.section42}><Text>11'</Text></View>
              <View style={styles.section43}>
                <View style={styles.section45}></View>
              </View>
              <View style={styles.section46}>
                <Text>Szent László Görögkatolikus Gim.</Text>
                <View style={styles.section48}>
                  <Text style={styles.section49}>b</Text>
                  <Text>91, 14, 25, 25Y, 12, 7, 91, 14, 25, 7, 91, 14, 25</Text>
                </View>
              </View>
            </View>

            <View style={styles.section41}>
              <View style={styles.section42}><Text>12'</Text></View>
              <View style={styles.section43}>
                <View style={styles.section45}></View>
              </View>
              <View style={styles.section46}>
                <Text>DAB-székház</Text>
                <View style={styles.section48}>
                  <Text style={styles.section49}>b</Text>
                  <Text>91, 14, 25, 25Y, 12, 7, 91, 14, 25, 7, 91, 14, 25</Text>
                </View>
              </View>
            </View>

            <View style={styles.section41}>
              <View style={styles.section42}><Text>13'</Text></View>
              <View style={styles.section43}>
                <View style={styles.section45}></View>
              </View>
              <View style={styles.section46}>
                <Text>Károlyi Mihány utca</Text>
                <View style={styles.section48}>
                  <Text style={styles.section49}>b</Text>
                  <Text>91, 14, 25, 25Y, 12, 7, 91, 14, 25, 7, 91, 14, 25</Text>
                </View>
              </View>
            </View>

            <View style={styles.section41}>
              <View style={styles.section42}><Text>14'</Text></View>
              <View style={styles.section43}>
                <View style={styles.section45}></View>
              </View>
              <View style={styles.section46}>
                <Text>Csokonai Vitéz Mihány Gimnázium</Text>
                <View style={styles.section48}>
                  <Text style={styles.section49}>b</Text>
                  <Text>91, 14, 25, 25Y, 12, 7, 91, 14, 25, 7, 91, 14, 25</Text>
                </View>
              </View>
            </View>

            <View style={styles.section41}>
              <View style={styles.section42}><Text>15'</Text></View>
              <View style={styles.section43}>
                <View style={styles.section45}></View>
              </View>
              <View style={styles.section46}>
                <Text>Kartács utca</Text>
                <View style={styles.section48}>
                  <Text style={styles.section49}>b</Text>
                  <Text>91, 14, 25, 25Y, 12, 7, 91, 14, 25, 7, 91, 14, 25</Text>
                </View>
              </View>
            </View>

            <View style={styles.section41}>
              <View style={styles.section42}><Text>16'</Text></View>
              <View style={styles.section43}>
                <View style={styles.section45}></View>
              </View>
              <View style={styles.section46}>
                <Text>Doberdó utca</Text>
                <View style={styles.section48}>
                  <Text style={styles.section49}>b</Text>
                  <Text>91, 14, 25, 25Y, 12, 7, 91, 14, 25, 7, 91, 14, 25</Text>
                </View>
                <View style={styles.section50}>
                  <Image src={Arrow} style={styles.arrow} />
                </View>
              </View>
            </View>

            <View style={styles.section41}>
              <View style={styles.section42}><Text>40'</Text></View>
              <View style={styles.section43}>
                <View style={styles.section45}></View>
              </View>
              <View style={styles.section47}><Text>Nagyállomás</Text></View>
            </View>

          </View>

        </View>
        <View style={styles.section6}>
          <View style={styles.section19}>
            <View style={styles.section17}>
              <Text>Indulási időpontok</Text>
            </View>
            <View style={styles.section18}>
              <Text>Érvényes: 2024.03.01</Text>
            </View>
          </View>

          <View style={styles.section20}>
  {/************ box1 */}
            <View style={styles.section21}>
              <View>
                <Text>Tanítási időszakban munaknapokon</Text>
              </View>
              <View style={styles.section22}>
                <Text>Workdays during school perios</Text>
              </View>

              <View style={styles.section23}>
                <View style={styles.section29}></View>

                <View style={styles.section26}>
                  <View style={styles.section24}><Text>04</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section28}>
                  <View style={styles.section24}><Text>05</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section26}>
                  <View style={styles.section24}><Text>06</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section28}>
                  <View style={styles.section24}><Text>07</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section26}>
                  <View style={styles.section24}><Text>08</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section28}>
                  <View style={styles.section24}><Text>09</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section26}>
                  <View style={styles.section24}><Text>10</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section28}>
                  <View style={styles.section24}><Text>11</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section26}>
                  <View style={styles.section24}><Text>12</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section28}>
                  <View style={styles.section24}><Text>13</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section26}>
                  <View style={styles.section24}><Text>14</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section28}>
                  <View style={styles.section24}><Text>15</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section26}>
                  <View style={styles.section24}><Text>16</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section28}>
                  <View style={styles.section24}><Text>17</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section26}>
                  <View style={styles.section24}><Text>18</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section28}>
                  <View style={styles.section24}><Text>19</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section26}>
                  <View style={styles.section24}><Text>20</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section28}>
                  <View style={styles.section24}><Text>21</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section26}>
                  <View style={styles.section24}><Text>22</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section28}>
                  <View style={styles.section24}><Text>23</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section26}>
                  <View style={styles.section24}><Text>00</Text></View>
                  <View style={styles.section25}></View>
                </View>

              </View>

            </View>
{/************ /box1 */}

{/************ box2 */}
            <View style={styles.section21}>
              <View>
                <Text>Tanítási szünetben munaknapokon</Text>
              </View>
              <View style={styles.section22}>
                <Text>Workdays during school holidays</Text>
              </View>

              <View style={styles.section23}>
                <View style={styles.section30}></View>

                <View style={styles.section31}>
                  <View style={styles.section24}><Text>04</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section32}>
                  <View style={styles.section24}><Text>05</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section31}>
                  <View style={styles.section24}><Text>06</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section32}>
                  <View style={styles.section24}><Text>07</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section31}>
                  <View style={styles.section24}><Text>08</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section32}>
                  <View style={styles.section24}><Text>09</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section31}>
                  <View style={styles.section24}><Text>10</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section32}>
                  <View style={styles.section24}><Text>11</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section31}>
                  <View style={styles.section24}><Text>12</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section32}>
                  <View style={styles.section24}><Text>13</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section31}>
                  <View style={styles.section24}><Text>14</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section32}>
                  <View style={styles.section24}><Text>15</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section31}>
                  <View style={styles.section24}><Text>16</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section32}>
                  <View style={styles.section24}><Text>17</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section31}>
                  <View style={styles.section24}><Text>18</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section32}>
                  <View style={styles.section24}><Text>19</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section31}>
                  <View style={styles.section24}><Text>20</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section32}>
                  <View style={styles.section24}><Text>21</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section31}>
                  <View style={styles.section24}><Text>22</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section32}>
                  <View style={styles.section24}><Text>23</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section31}>
                  <View style={styles.section24}><Text>00</Text></View>
                  <View style={styles.section25}></View>
                </View>

              </View>

            </View>
            {/************ /box2 */}

          </View>

          <View style={styles.section20}>
  {/************ box3 */}
            <View style={styles.section21}>
              <View>
                <Text>Szombaton, vasárnap és ünnepnapokon</Text>
              </View>
              <View style={styles.section22}>
                <Text>Saturdays, Sundays and holidays</Text>
              </View>

              <View style={styles.section23}>
                <View style={styles.section38}></View>

                <View style={styles.section33}>
                  <View style={styles.section24}><Text>04</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section34}>
                  <View style={styles.section24}><Text>05</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section33}>
                  <View style={styles.section24}><Text>06</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section34}>
                  <View style={styles.section24}><Text>07</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section33}>
                  <View style={styles.section24}><Text>08</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section34}>
                  <View style={styles.section24}><Text>09</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section33}>
                  <View style={styles.section24}><Text>10</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section34}>
                  <View style={styles.section24}><Text>11</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section33}>
                  <View style={styles.section24}><Text>12</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section34}>
                  <View style={styles.section24}><Text>13</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section33}>
                  <View style={styles.section24}><Text>14</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section34}>
                  <View style={styles.section24}><Text>15</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section33}>
                  <View style={styles.section24}><Text>16</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section34}>
                  <View style={styles.section24}><Text>17</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section33}>
                  <View style={styles.section24}><Text>18</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section34}>
                  <View style={styles.section24}><Text>19</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section33}>
                  <View style={styles.section24}><Text>20</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section34}>
                  <View style={styles.section24}><Text>21</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section33}>
                  <View style={styles.section24}><Text>22</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section34}>
                  <View style={styles.section24}><Text>23</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section33}>
                  <View style={styles.section24}><Text>00</Text></View>
                  <View style={styles.section25}></View>
                </View>

              </View>

            </View>
{/************ /box3 */}

{/************ box4 */}
            <View style={styles.section21}>
              <View>
                <Text>December 24-én</Text>
              </View>
              <View style={styles.section22}>
                <Text>On December 24th</Text>
              </View>

              <View style={styles.section23}>
                <View style={styles.section37}></View>

                <View style={styles.section35}>
                  <View style={styles.section24}><Text>04</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section36}>
                  <View style={styles.section24}><Text>05</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section35}>
                  <View style={styles.section24}><Text>06</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section36}>
                  <View style={styles.section24}><Text>07</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section35}>
                  <View style={styles.section24}><Text>08</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section36}>
                  <View style={styles.section24}><Text>09</Text></View>
                  <View style={styles.section25}>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                    <Text style={styles.section27}>26</Text>
                    <Text style={styles.section27}>46</Text>
                  </View>
                </View>

                <View style={styles.section35}>
                  <View style={styles.section24}><Text>10</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section36}>
                  <View style={styles.section24}><Text>11</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section35}>
                  <View style={styles.section24}><Text>12</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section36}>
                  <View style={styles.section24}><Text>13</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section35}>
                  <View style={styles.section24}><Text>14</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section36}>
                  <View style={styles.section24}><Text>15</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section35}>
                  <View style={styles.section24}><Text>16</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section36}>
                  <View style={styles.section24}><Text>17</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section35}>
                  <View style={styles.section24}><Text>18</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section36}>
                  <View style={styles.section24}><Text>19</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section35}>
                  <View style={styles.section24}><Text>20</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section36}>
                  <View style={styles.section24}><Text>21</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section35}>
                  <View style={styles.section24}><Text>22</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section36}>
                  <View style={styles.section24}><Text>23</Text></View>
                  <View style={styles.section25}></View>
                </View>

                <View style={styles.section35}>
                  <View style={styles.section24}><Text>00</Text></View>
                  <View style={styles.section25}></View>
                </View>

              </View>

            </View>
            {/************ /box4 */}

          </View>





        </View>
      </View>

      <View style={styles.section8}>
        <View style={styles.section9}></View>
        <View style={styles.section10}><Image src={GreenLine} style={styles} /><Image src={DkvLogo} style={styles.dkv_logo_white} /></View>
      </View>
    </Page>
  </Document>

);
export default Pdf;