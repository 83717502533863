import React from 'react';
import './TimeTable.css';

const TimeTable = props => {

    if (props.length === 0) {
        return (
            <div className='time-box'>
                <div className='time-row-disable'>Ebben az időszakban nem közlekedik járat</div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className='time-box'>
                {props.items.data.rows_returned === 0
                ? <div className='time-row-disable'>Ebben az időszakban nem közlekedik járat</div> 
                : props.items.data.liveStopTimes.map((data, index) => (
                    <div key={index} className='time-row'>
                        <div className='time-num'>{props.hour}:{data.ind}</div>
                        {data.f_specepit === 'VSPA' ? <div className='low-ride'>Alacsonypadlós jármű</div> : null }
                    </div>
                ))}
            </div>
        </React.Fragment>
    );

};

export default TimeTable;