import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../hooks/http-hook';
import LoadingSpinner from '../components/LoadingSpinner';
import LineList from '../components/LineList';

const Lines = () => {
    const [loadedLines, setLoadedLines] = useState();
    const { isLoading, sendRequest } = useHttpClient();

    useEffect(() => {
        const fetchRequest = async () => {
            const apiKey = 'Njy59SaRSe53LsDUQY9hIhrfxSp4V1kjvVRzD9EHG0pIHTtT1F';
            const baseUrl = `https://www.menetrend-debrecen.hu/menetrend_backend`;
            const options = {
                url:  `${baseUrl}/lines/0`,
                method: 'GET',
                body: null,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                }
            }
            try {
            const responseData = await sendRequest(
                options.url,
                options.method,
                options.body,
                options.headers
            );
            setLoadedLines(responseData.data);
            } catch (err) {}
        };
        fetchRequest();
        }, [sendRequest]);

    return (
        <React.Fragment>
            {isLoading && (
                <div className="center">
                    <LoadingSpinner asOverlay />
                </div>
            )}
            {!isLoading && loadedLines && (
              <LineList items={loadedLines.lines} content='1' />
            )}
        </React.Fragment>
      );

}

export default Lines;