import React from 'react';

const SignList = props => {

    if (props.loadedSigns.data === null) {
        return (
            <></>
        );
    }

    return (
        <React.Fragment>
            {props.loadedSigns.signs.map((data, key) => (
                <div key={key}><span style={{fontWeight:'bold'}}>{data.sign}:</span> {data.f_nev}</div>
            ))}
        </React.Fragment>
      );
   };
   
   export default SignList;