import React, { } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './StopList.css';

const StopList = props => {
    const { line_name, direction, date, live, sort } = useParams();
    const navigate = useNavigate();

    if (props.items.rows_returned === 0) {
        return (
            <div className='stops-container'>Nincs adat.</div>
        );
    }

    const handleRowClick = (data) => {
        navigate(`../${line_name}/${direction}/${data.f_sorrend}/${live}/${date}`);
    };

    function areAllLinesEqual(lines) {
        // Ellenőrizzük, hogy a tömb üres-e vagy csak egy elemet tartalmaz-e
        if (lines.length === 0 || lines.length === 1) {
            return true;
        }
        // Az első elem értékének megszerzése
        const firstLineValue = lines[0];
        // Ellenőrizzük, hogy minden elem megegyezik-e az első elem értékével
        return lines.every(line => line === firstLineValue);
    }

    const allLinesAreEqual = areAllLinesEqual(props.items.lines);

    
    return (
        <div className='stops-container'>
            <table className='stops-table' cellSpacing="0" cellPadding="0">
                <thead>
                    <tr>
                        <th rowSpan='2' className='megallo'>Megálló</th>
                        <th colSpan={props.items.rows_returned}>Menetidő</th>
                    </tr>
                    <tr>
                        {props.items.lines.map((line, index3) => (
                            <th key={index3.toString()}>
                                {allLinesAreEqual ? <span>{index3 + 1}</span> : <span>{line}</span>}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                {props.items.travelTime.map((data, index) => (
                    <tr 
                        key={index.toString()} 
                        className={data.f_sorrend.toString() === sort ? 'active' : props.items.travelTime.length === data.f_sorrend ? 'disabled-link' : 'stops-item'}
                        onClick={() => handleRowClick(data)}
                    >
                        <td className='stops-item-tt'>{data.f_nev}</td>
                        {props.items.travelTime[index].times.map((time, index2) => (
                            <td 
                                key={index2.toString()} 
                                className='stops-item-tt2'
                            >
                                {time != null ? <span className={props.items.classes[index2] && props.items.classes[index2]}>{time}</span> : <span>-</span>}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
            <tfoot></tfoot>
            </table>
        </div>
    );
};

export default StopList;