import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useHttpClient } from '../hooks/http-hook';
import Stops from '../components/Stops';
import StopTimes from '../components/StopTimes';
import LineHeader from '../components/LineHeader';
import './LineDetails.css';
//import TravelTime from '../components/TravelTime';
import TimeDescription from '../components/TimeDescription';
import LoadingSpinner from '../components/LoadingSpinner';
import LineDialog from '../components/LineDialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const LineDetails = () => {

  const restrictedLines = {
    '60h': 'v',
    '36e': 'v',
    '10a': 'o',
    '125': 'o'
  };

  const { line_name: initialLineName } = useParams();
  const [lineName, setLineName] = useState(initialLineName);
  const navigate = useNavigate();
  const { sort, direction, date, live } = useParams();
  const validDirection = restrictedLines[lineName] || direction; // kivétel!!!!
  const [loadedMainData, setLoadedMainData] = useState();
  const { isLoading, sendRequest } = useHttpClient();
  const [group, setGroup] = useState(0);
  const [switchButton, setSwitchButton] = useState(false);
  const [sharedClass, setSharedClass] = useState('');
  const [sharedNyomvid, setSharedNyomvid] = useState('');
  const regex = new RegExp(" \\/ ", "g");

  const updateLineName = (newLineName) => {
    setLineName(newLineName);
    navigate(`/${newLineName}/${direction}/${sort}/${live}/${date}`);
  };

  const handleSwitchButton = (event) => {
    const isSwitchOn = event.target.checked;
    setSwitchButton(isSwitchOn);
    setGroup(isSwitchOn ? 1 : 0);
    group === 0 ? updateLineName(formatString(loadedMainData.mainData[0].group_vonal)) : updateLineName(loadedMainData.mainData[0].f_vonal_group)
  };

  useEffect(() => {
    setLineName(initialLineName);
  }, [initialLineName]);

  const formatString = (inputString) => {
    return inputString.split(' / ').join(',');
  };

  const buttonStyle2 = {
    display:'flex',
    backgroundColor:'#ffd200',
    color:'#000',
    fontWeight:'bold',
    border:'none',
    borderRadius:'20px',
    fontSize:'16px',
    "&:hover": {
      background: "#ffe930",
      border:'none'
    },
    "@media (max-width: 600px)": {
      fontSize:'14px',
      padding: '10px 15px',
      lineHeight:'20px'
    }
  }

  const labelStyle = {
    fontWeight:'700',
    marginLeft:'10px'
  }

  useEffect(() => {
    const fetchRequest = async () => {
        const apiKey = 'Njy59SaRSe53LsDUQY9hIhrfxSp4V1kjvVRzD9EHG0pIHTtT1F';
        const baseUrl = `https://www.menetrend-debrecen.hu/menetrend_backend`;
        const options = {
            url:  `${baseUrl}/main-data/${lineName}/${validDirection}`,
            method: 'GET',
            body: null,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}`
            }
        }
        try {
          const responseData = await sendRequest(
              options.url,
              options.method,
              options.body,
              options.headers
          );
          if (responseData.success) {
          setLoadedMainData(responseData.data);
        }
        } catch (err) {}
    };
    fetchRequest();
  }, [sendRequest,lineName,validDirection,navigate]);

    return (
      <div className='main-container1'>
        <div className="line-header">
          {isLoading && (
            <div className="center">
              <LoadingSpinner asOverlay />
            </div>
            )}
          {!isLoading && loadedMainData && (
          <LineHeader items={loadedMainData} group={group} handleSwitchButton={handleSwitchButton} lineName={lineName} direction={validDirection} restrictedLines={restrictedLines}/>
          )}
        </div>
        <div className='dialog-button-3'>
          <div className="dialog-button-1">
            {!isLoading && loadedMainData && (loadedMainData.mainData[0].name !== loadedMainData.mainData[0].group_name && 
              <FormGroup>
                <FormControlLabel 
                  className='form-label' 
                  control={<Switch checked={switchButton} onChange={handleSwitchButton} className='switch-button' />} 
                  label={<Typography sx={labelStyle}>VONALCSOPORTOK</Typography>} 
                />
              </FormGroup>
            )}
          </div>
          <div className="dialog-button-2">
            <LineDialog content='0' group={group} handleSwitchButton={handleSwitchButton}/>

            {!isLoading && loadedMainData && (
                      
            <a className='yellow-button' target='blank_' href={`/dkv-basesite/hirdetmeny/${sort}_${loadedMainData.mainData[0].group_name.replace(regex, '_').toLowerCase()}_${direction}.pdf`} title='Nyomtatható PDF'>NYOMTATHATÓ PDF</a>)}

            {loadedMainData && 
            <Link to={`/live/${lineName.split(',')[0]}/${(lineName === '60h' || lineName === '36e') ? 'v' : 'o'}/1/1/${date}`} title='Napi menetrend'>
                <Button variant="outlined" sx={buttonStyle2}>Napi menetrend</Button>
            </Link>
            }
          </div>
        </div>
        <div className='body-container1'>
          <div className='top-side1'>
            <div className='table-container1'>
              <Stops sharedClass={sharedClass} setSharedClass={setSharedClass} sharedNyomvid={sharedNyomvid} setSharedNyomvid={setSharedNyomvid}/>
              {isLoading && (
                <div className="center">
                    <LoadingSpinner asOverlay />
                </div>
            )}
            </div>
            <div className="stop-times1">
              <StopTimes sharedClass={sharedClass} sharedNyomvid={sharedNyomvid} />
              <TimeDescription />
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default LineDetails;