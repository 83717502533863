import React, { useEffect, useState } from 'react';
import './LineHeader.css';
import { Link, useParams } from 'react-router-dom';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const LiveLineHeader = props => {
  const { date, live } = useParams();
  const [directionValue, setDirectionValue] = useState(props.direction);

  useEffect(() => {
    // Frissítés a korlátozott vonalak ellenőrzésével
    const newDirection = getRestrictedDirection(props.lineName) || props.direction;
    setDirectionValue(newDirection);
  }, [props.lineName, props.direction, props.restrictedLines]);

  const getRestrictedDirection = line => {
    return props.restrictedLines[line] || null;
  };

  const handleClick = () => {
    // Irányváltás kezelése, ha a vonal nem korlátozott
    if (!getRestrictedDirection(props.lineName)) {
      let newDirection = directionValue === 'o' ? 'v' : 'o';
      setDirectionValue(newDirection);
    }
  };

  const calculateLinkPath = (lineCode, live, date) => {
    const direction = getRestrictedDirection(lineCode) || 'o';
    return `/live/${lineCode}/${direction}/1/${live}/${date}`;
  };

  const isRestrictedLine = line => props.restrictedLines.hasOwnProperty(line);

  function splitString(inputString) {
    return inputString.split(/ \/\s/);
  }

  function splitString2(inputString) {
    return inputString.split(/,\s*/);
  }

  const switchCssClass = (teljtip, night, airport, shopping) => {
    switch (teljtip) {
      case 'VI':
        return 'line-header-sign-v';
      case 'TR':
        return 'line-header-sign-t';
      case 'AU':
        if (night === '1') return 'line-header-sign-n';
        else if (airport === '1') return 'line-header-sign-air';
        else if (shopping === '1') return 'line-header-sign-a';
        else return 'line-header-sign';
      default:
        return 'line-header-sign';
    }
  };

  return (
    <>
      <div className='lines-header'>
        <div className='header-sign'>
          <div className={switchCssClass(props.items.mainData[0].f_teljtip, props.items.mainData[0].night, props.items.mainData[0].airport, props.items.mainData[0].shopping)}>
            {props.items.mainData[0].name}</div>
          <div className='line-header-name'>
            <div className="line-header-name-text">{props.items.mainData[0].f_nev}</div>
            <div className="line-header-name-list">
              {splitString(props.items.mainData[0].group_name).map((data, index) => {
                const lineCode = splitString2(props.items.mainData[0].group_vonal.toLowerCase())[index];
                return (
                  data !== props.items.mainData[0].name &&
                  <Link key={'link_' + index} to={calculateLinkPath(lineCode, live, date)}>
                    <div key={index} className={switchCssClass(props.items.mainData[0].f_teljtip, props.items.mainData[0].night, props.items.mainData[0].airport, props.items.mainData[0].shopping)} style={{ fontSize: '16px', fontWeight: 'bold', padding: '0px 10px', marginRight: '2px' }}>
                      {data}
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
          {props.items.mainData[0].one_way === 0 && !isRestrictedLine(props.lineName) &&
            <div className='inverse'>
            <Link onClick={handleClick} to={`/live/${props.lineName}/${directionValue === 'o' ? 'v' : 'o'}/1/${live}/${date}`} title='Ellenkező menetirány' className="direction-button">
                <SwapVertIcon className="direction-icon" sx={{ width: '30px', height: '30px', padding: '5px', marginLeft: '20px', color: '#000', backgroundColor: '#ffd200', borderRadius: '50%', fontSize: 'small' }} />
              </Link>
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default LiveLineHeader;
