import React from 'react';
import LiveStopList from './LiveStopList';

const LiveStops = props => {

        return (
        <React.Fragment>
            <LiveStopList items={props.loadedStops} />
        </React.Fragment>
      );

}

export default LiveStops;