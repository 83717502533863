import React from 'react';

const LineCard = props => {

    return (
        <div>
            {props.route_id}
        </div>
    );

};

export default LineCard;