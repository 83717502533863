import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './LiveStopList.css';
import Up from '@mui/icons-material/ArrowCircleUpOutlined';
import Down from '@mui/icons-material/ArrowCircleDownOutlined';

const LiveStopList = props => {
  const { line_name, direction, sort, live, date } = useParams();
  const [activeStop, setActiveStop] = useState(parseInt(sort));
  const scrollContainerRef = useRef(null);
  const navigate = useNavigate();

  const handleScroll = (scrollDirection) => {
    const scrollAmount = scrollDirection === 'up' ? -100 : 100;
    scrollContainerRef.current.scrollBy({
      top: scrollAmount,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setActiveStop(parseInt(sort));
  }, [sort]);

  useEffect(() => {
    if (scrollContainerRef.current && props.items.travelTime.length > 0) {
      const allPoints = scrollContainerRef.current.querySelectorAll('.point-row');
      let totalOffsetTop = 0;
      let activeElementHeight = 0;
      allPoints.forEach((elem, index) => {
        if (index < activeStop) {
          totalOffsetTop += elem.offsetHeight + 10; // Feltételezve, hogy minden elem között 10px a margin
        } else if (index === activeStop) {
          activeElementHeight = elem.offsetHeight;
        }
      });
  
      // A látható terület magasságának és az aktív elem magasságának felhasználásával kiszámítjuk, hogy mennyit kell görgetni.
      const visibleHeight = scrollContainerRef.current.offsetHeight;
      const halfVisibleHeight = visibleHeight / 1.5;
      const activeElementOffset = totalOffsetTop + (activeElementHeight / 2);
      const scrollTo = activeElementOffset - halfVisibleHeight;
  
      scrollContainerRef.current.scrollTo({
        top: scrollTo > 0 ? scrollTo : 0, // Biztosítjuk, hogy a görgetési érték ne legyen negatív
        behavior: 'smooth'
      });
    }
  }, [activeStop, props.items.travelTime]);
  
  const handleStopClick = (data, date) => {
    setActiveStop(data);
    navigate(`../live/${line_name}/${direction}/${data}/${live}/${date}`);
  };

  function handleDirection(direction,line) {
    const parts = direction.split('-');
    if(line === '1'|| line === '2') return 'o';
    switch(parts[1]){
      case '1': return 'o';
      case '2': return 'v';
      case '3': return 'o';
      case '4': return 'v';
      default : return 'o';
    }
  }

  const handleStopClick2 = (line, data, date, dir) => {
    setActiveStop(data);
    const dirMod = handleDirection(dir,line);
    navigate(`../live/${line}/${dirMod}/${data}/${live}/${date}`);
  };

  return (
    <React.Fragment key='sort'>
      <div className="list-table">
        <div className='arrow-button' style={{ borderBottom: '1px solid #3c68b9' }}>
          <div className='box-title-lines'>Válasszon megállót!</div>
          <Up className="icons" onClick={() => handleScroll('up')} />
        </div>
        <div className='live-stops-box' style={{ overflow: 'hidden' }}>
          <div className='stops-boundary'
            ref={scrollContainerRef}
            style={{
              maxHeight: '400px',
              overflowY: 'scroll',
              scrollbarWidth: 'thin',
              overflowX: 'hidden',
              scrollbarColor: '#103475 #ccc',
              width: '100%'
            }}
          >
            <div className='live-stops-line'>
              {props.items.travelTime.map((data, index) => (
                <div 
                  className='point-row'
                  key={data.f_sorrend}
                  style={{
                    cursor: 'pointer',
                    //pointerEvents: props.items.travelTime.length - 1 === index ? 'none' : 'all'
                  }}
                >
                  <div className='travel-time-text'>{data.times[0]}{data.times[0] ? "'" : null}</div>
                  {/*
                  <div  onClick={() => handleStopClick(data.f_sorrend, date)} className={`active-point ${index === 0 ? 'start' : index === props.items.travelTime.length - 1 ? 'end' : 'point'}`} style={data.f_sorrend === activeStop ? { backgroundColor: '#ffe930', width: '36px', height: '36px', border: '3px solid #1a499f', right: '40px', bottom: '5px', transition: 'all 0.3s ease' } : null}></div>
                  */}
                  <div  onClick={() => handleStopClick(data.f_sorrend, date)} className={`active-point ${index === 0 ? 'start' : index === props.items.travelTime.length - 1 ? 'end' : 'point'}`} style={data.f_sorrend === activeStop ? { backgroundColor: '#ffe930', width: '36px', height: '36px', border: '3px solid #1a499f', right: '40px', bottom: '5px', transition: 'all 0.3s ease' } : null}></div>

                  <div className='station' key={"station_" + data.f_sorrend}>
                    <div onClick={() => handleStopClick(data.f_sorrend, date)} className='station-name' key={'station-name_' + data.f_sorrend} style={data.f_sorrend === activeStop ? { textDecoration: 'underline' } : null}>
                      {data.f_nev}
                    </div>
                    <div className='connected' key={'connected1_' + data.f_sorrend}>
                      {data.connected_lines.map((data2, index2) => (
                        data.connected_type[index2] === 'VI' && <div onClick={() => handleStopClick2(data2, data.connected_sorrend[index2], date, data.connected_nyomvon[index2])} className='point2-vi' key={'key2_' + index2}>{data2}</div>
                      ))}
                    </div>
                    <div className='connected' key={'connected3_' + data.f_sorrend}>
                      {data.connected_lines.map((data2, index2) => (
                        data.connected_type[index2] === 'TR' && <div onClick={() => handleStopClick2(data2, data.connected_sorrend[index2], date ,data.connected_nyomvon[index2])} className='point2-tr' key={'key2_' + index2}>{data2}</div>
                      ))}
                    </div>
                    <div className='connected' key={'connected2_' + data.f_sorrend}>
                      {data.connected_lines.map((data2, index2) => (
                        data.connected_type[index2] === 'AU' && <div onClick={() => handleStopClick2(data2, data.connected_sorrend[index2], date, data.connected_nyomvon[index2])} className='point2-au' key={'key2_' + index2}>{data2}</div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='arrow-button-bottom' style={{ borderTop: '1px solid #3c68b9' }}>
          <Down className="icons" onClick={() => handleScroll('down')} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default LiveStopList;
